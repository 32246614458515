import { DateLineChartData, DateRange, Granularity } from "@/helpers/analytics";
import { AnalyticsData, StatusStats } from "@/services/analytics-service";
import dayjs from "dayjs";
import Vue from "vue";
import config from "@/config";
import { StatusMetadata, AllStatusesConfig } from "@/services/configuration-service";

export enum CaseStatus {
  New = 0,
  InProgress = 1,
  Closed = 2,
  Processed = 3
}

function getStatusText(value: number|CaseStatus) {
  for(const configStatus of config.caseStatus){
    if(configStatus.value === value){
      return configStatus.name;
    }
  }
  return "Unknown";
}

Vue.filter(
  'status',
  (value: number) => getStatusText(value)
);

function returnedResults(
  data: StatusStats[], 
  includeNew = false,
  includeInProgress = false,
  includeVerified = false,
  includeClosed = false
): DateLineChartData {
  const results = { datasets: [] } as DateLineChartData;

    results.datasets.push({
      label: getStatusText(CaseStatus.New),
      data: data.filter(x => x.Status == CaseStatus.New).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(255, 0, 0)",
      backgroundColor: "rgb(255, 0, 0, 0.3)",
      tension: 0.2,
      pointRadius: 0,
      hidden: !includeNew
    });


  if (includeInProgress) {
    results.datasets.push({
      label: getStatusText(CaseStatus.InProgress),
      data: data.filter(x => x.Status == CaseStatus.InProgress).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(128, 0, 255)",
      backgroundColor: "rgb(128, 0, 255, 0.3)",
      tension: 0.2,
      pointRadius: 0
    });
  }

  if (includeClosed) {
    results.datasets.push({
      label: getStatusText(CaseStatus.Closed),
      data: data.filter(x => x.Status == CaseStatus.Closed).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(0, 34, 255)",
      backgroundColor: "rgb(0, 34, 255, 0.3)",
      tension: 0.2,
      pointRadius: 0
    })
  }

  if (includeVerified) {
    results.datasets.push({
      label: getStatusText(CaseStatus.Processed),
      data: data.filter(x => x.Status == CaseStatus.Processed).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(32, 201, 32)",
      backgroundColor: "rgb(32, 201, 32, 0.3)",
      tension: 0.2,
      pointRadius: 0
    });
  }

  return results;
}

export function calculateCasesByStatusChartData(
  casesByStatusStats: AnalyticsData<StatusStats> | null,
  casesByStatusDateRange: DateRange
): DateLineChartData {
  if (casesByStatusStats == null) {
    return { datasets: [] };
  }

  const now = dayjs();
  const from = now.subtract(casesByStatusDateRange, "days");
  const compare = from.startOf('hour');

  let slice = casesByStatusStats.data.filter(x => compare.isSameOrBefore(x.Time));

  slice = slice
    .reduce(
      (arr, stat) => {
        const timestamp = dayjs(stat.Time)
          .startOf("hour")
          .toDate();
        const existing = arr.find(
          x => x.Time.getTime() == timestamp.getTime()
          && x.Status == stat.Status
        );
        if (existing) {
          existing.Total = stat.Total;
          return arr;
        }
        return [...arr, { ...stat, Time: timestamp }];
      },
      [] as StatusStats[]
    );

    return returnedResults(slice, false, true);
}

export function calculateCasesByMetadataChartData(
  casesByStatusStats: AnalyticsData<StatusStats> | null,
  casesByStatusDateRange: DateRange
): DateLineChartData {
  if (casesByStatusStats == null) {
    return { datasets: [] };
  }

  const now = dayjs();
  const from = now.subtract(casesByStatusDateRange, "days");
  const compare = from.startOf('hour');

  let slice = casesByStatusStats.data.filter(x => compare.isSameOrBefore(x.Time));

  slice = slice
    .reduce(
      (arr, stat) => {
        const timestamp = dayjs(stat.Time)
          .startOf("hour")
          .toDate();
        const existing = arr.find(
          x => x.Time.getTime() == timestamp.getTime()
          && x.Status == stat.Status
        );
        if (existing) {
          existing.Total = stat.Total;
          return arr;
        }
        return [...arr, { ...stat, Time: timestamp }];
      },
      [] as StatusStats[]
    );

    return returnedResults(slice, false, true);
}


export function calculateActivityChartData(
  activityStats: AnalyticsData<StatusStats> | null,
  activityDateRange: DateRange,
  activityGranularity: Granularity
): DateLineChartData {


  if (activityStats == null) {
    return { datasets: [] };
  }

  const now = dayjs().utc().startOf("hour");
  const from = now.subtract(activityDateRange, "days");
  const zeros = [] as StatusStats[];
  let date = from.startOf("hour");
  while (!date.isAfter(now)) {
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.InProgress,
      Total: 0
    });
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.New,
      Total: 0
    });
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.Processed,
      Total: 0
    });
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.Closed,
      Total: 0
    });
    date = date.add(1, "hour");
  }

  const compare = from.startOf('hour');

  let slice = zeros.concat(
    activityStats.data.filter(x => compare.isSameOrBefore(x.Time))
  );

  slice = slice
    .reduce(
      (arr, stat) => {
        const timestamp = dayjs(stat.Time).utc()
          .startOf(activityGranularity == Granularity.Day ? "day" : "hour")
          .toDate();
        const existing = arr.find(
          x => x.Time.getTime() == timestamp.getTime()
          && x.Status == stat.Status
        );
        if (existing) {
          existing.Total += stat.Total;

          return arr;
        }
        return [...arr, { ...stat, Time: timestamp }];
      },
      [] as StatusStats[]
    );

    return returnedResults(slice, true, true, true, true);
}

export interface StatusMetadataTableEntry {
  Name: string;
  Total: number;
}

function initializeZeroTable(foundConfig: StatusMetadata): StatusMetadataTableEntry[] {
  const zeros = [] as StatusMetadataTableEntry[];
  foundConfig.values.forEach((foundConfigValue) => {
    zeros.push({Name: foundConfigValue.value, Total: 0} as StatusMetadataTableEntry)
  });

  return zeros;
}

function lookupName(
  name: string, 
  config: StatusMetadata
  ): string {
    return config.values.find(x => x.value == name)?.displayName ?? "";
}

export function calculateMetadataTable(
  statusMetadataConfig: AllStatusesConfig,
  casesByMetadataStats: AnalyticsData<StatusStats> | null,
  casesByMetadataFrom: Date,
  casesByMetadataTo: Date,
  casesByMetadataStatus: CaseStatus,
  casesByPropertyAggregator: string,
  casesByPropertyFilter: string,
  casesByPropertyFilterValue: string
): StatusMetadataTableEntry[] {
  if (casesByMetadataStats == null) {
    return [];
  }
  const from = dayjs(casesByMetadataFrom ? casesByMetadataFrom : new Date(1900, 0, 1)).utc().startOf("hour");
  const to = dayjs(casesByMetadataTo ? casesByMetadataTo: dayjs()).utc().startOf("hour");

  const selectedConfig = statusMetadataConfig.statuses.find(x => x.id === casesByMetadataStatus as number)
  const foundConfig = selectedConfig?.metadata.find(x => x.name == casesByPropertyAggregator);
  if (!foundConfig) {
    return [];
  }

  const zeros = initializeZeroTable(foundConfig);

  const slice = casesByMetadataStats.data.filter(x => x.Status == casesByMetadataStatus).reduce((countOfConfigValues, item) => {
    if (
        from.isSameOrBefore(item.Time)
        && to.isSameOrAfter(item.Time)
      ) {
      const foundMetadata = item.Metadata?.find(x => x.Key == foundConfig.name);

      if (foundMetadata) {
        if(casesByPropertyFilter && casesByPropertyFilterValue) {
          if (!item.Metadata?.find(x => x.Key == casesByPropertyFilter && x.Value == casesByPropertyFilterValue))
            return countOfConfigValues;
        }
        const existing = countOfConfigValues.find(x => x.Name == foundMetadata.Value);
        if (existing) {
          existing.Total += item.Total;
        }
      }
    }

    return countOfConfigValues;
  }, zeros)

  return slice
    .map(x => { 
      return { 
        ...x, 
        Name: lookupName(x.Name, foundConfig) 
      } 
    })
    .sort((a, b) => a.Name < b.Name ? -1 : 0);
}

export function calculateMetadataChart(
  casesByMetadataStats: AnalyticsData<StatusStats> | null
): DateLineChartData {
  if (casesByMetadataStats == null) {
    return { datasets: [] };
  }

  return { datasets: [] } as DateLineChartData;
}




















import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/material/Card.vue";
import HazcheckDetectAnalytics from "@/components/analytics/hazcheck-detect/hazcheck-detect.vue";
import CaseManagementAnalytics from "@/components/analytics/case-management/case-management.vue";
import config from "@/config";

@Component({
  components: {
    Card,
    HazcheckDetectAnalytics,
    CaseManagementAnalytics
  }
})
export default class Analytics extends Vue {
  tab = null;

  get showCaseManagement() {return config.showCaseManagement; }
}









































































































import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import AnalyticsService, { StatusStats, AnalyticsData } from "@/services/analytics-service";
import {
  calculateMetadataTable,
  StatusMetadataTableEntry,
  CaseStatus
} from "../case-management-func";
import LineChart from "@/components/charts/LineChart.vue";
import Config from "@/config";
import ConfigurationService, { AllStatusesConfig } from "@/services/configuration-service";

import DateRangeSelect from "../../shared/date-range-select.vue";
import GranularitySelect from "../../shared/granularity-select.vue";
import TotalSheet from "../../shared/total-sheet.vue";
import DateSelect from "@/components/common/date-select.vue"
import dayjs from "dayjs";

@Component({
  components: {
    LineChart,
    TotalSheet,
    DateRangeSelect,
    GranularitySelect,
    DateSelect
  }
})
export default class CaseManagementMetadataTable extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;
  @Inject() ConfigurationService!: ConfigurationService;

  loading = true;

  casesByMetadata: AnalyticsData<StatusStats> | null = null;
  casesByMetadataFrom = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
  casesByMetadataTo = dayjs().format('YYYY-MM-DD');
  casesByMetadataStatus = CaseStatus.Closed;
  casesByMetadataAggregatorProperty = "";
  casesByMetadataFilterProperty = "";
  casesByMetadataFilterValue = "";

  async mounted() {
    await this.fetchAnalytics();
    await this.fetchConfiguration();
  }

  async fetchAnalytics() {
    this.casesByMetadata = await this.AnalyticsService.readCasesByMetadata();
  }

  metadataConfig: AllStatusesConfig = { statuses: [] };

  async fetchConfiguration() {
    this.metadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
  }

  get metadataStatus(): StatusMetadataTableEntry[] {
    const results = calculateMetadataTable(
      this.metadataConfig,
      this.casesByMetadata,
      new Date(this.casesByMetadataFrom),
      new Date(this.casesByMetadataTo),
      this.casesByMetadataStatus,
      this.casesByMetadataAggregatorProperty,
      this.casesByMetadataFilterProperty,
      this.casesByMetadataFilterValue
    );
    return results;
  }

  caseStatusItems = Config.caseStatus;

  get caseMetadataProperties() {
    const configBlock = this.metadataConfig.statuses.find(x => x.id == this.casesByMetadataStatus);
    const configItems =
      configBlock?.metadata
        .map(x => {
          return { text: x.name, value: x.name };
        })
        .sort((a, b) => (a < b ? -1 : 1)) ?? [];
    return configItems;
  }

  get caseMetadataFilterProperties() {
    const filteredProperties = this.caseMetadataProperties?.filter(
      x => x.value != this.casesByMetadataAggregatorProperty
    );

    filteredProperties.unshift({ text: "None", value: "" });

    return filteredProperties;
  }

  get caseMetadataPropertyValues() {
    const selectedStatus = this.metadataConfig.statuses.find(
      x => x.id == this.casesByMetadataStatus
    );
    const selectedFilter = selectedStatus?.metadata.find(
      x => x.name == this.casesByMetadataFilterProperty
    );
    return selectedFilter?.values;
  }

  @Watch("casesByMetadataStatus")
  resetAggregate() {
    this.casesByMetadataAggregatorProperty = "";
  }

  @Watch("casesByMetadataAggregatorProperty")
  resetFilters() {
    this.casesByMetadataFilterProperty = "";
    this.casesByMetadataFilterValue = "";
  }
}

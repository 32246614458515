




















import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import TotalSheet from "@/components/analytics/shared/total-sheet.vue";
import DateRangeSelect from "@/components/analytics/shared/date-range-select.vue";
import GranularitySelect from "@/components/analytics/shared/granularity-select.vue";
import NumberSelect from "@/components/analytics/shared/number-select.vue";
import AnalyticsService, { ScreeningStats, AnalyticsData } from "@/services/analytics-service";
import LineChart from "@/components/charts/LineChart.vue";
import { ChartData, ChartOptions } from "chart.js";
import { DateRange, Granularity } from "@/helpers/analytics";
import { calculateScreeningsChartData } from "@/components/analytics/hazcheck-detect/hazcheck-detect-func";

type DateLineChartData = ChartData<"line", { y: number; x: Date }[], Date>;

@Component({
  components: {
    LineChart,
    TotalSheet,
    DateRangeSelect,
    GranularitySelect,
    NumberSelect
  }
})
export default class HazcheckDetectScreeningsChart extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;
  @Prop() screenings: AnalyticsData<ScreeningStats> | null = null;

  @Prop() loading!: boolean;

  screeningsGranularity = Granularity.Hour;
  screeningsDateRange = DateRange.Week;
  screeningsChartOptions: ChartOptions<"line"> = {
    plugins: {
      decimation: {
        enabled: true,
        algorithm: "lttb"
      }
    },
    interaction: {
      intersect: false,
      axis: "x"
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        }
      }
    }
  };

  get totalScreenings() {
    return this.screenings?.data?.reduce((a, b) => a + b.Screenings, 0);
  }
  get analyticsCollectionStartDate() {
    const sorted = this.screenings?.data.sort(
      (a, b) => a.Timestamp.getTime() - b.Timestamp.getTime()
    );
    return sorted && sorted.length && sorted[0].Timestamp;
  }

  get lastUpdated() {
    return this.screenings?.lastUpdated;
  }

  @Watch("screeningsDateRange")
  onChangeScreeningsDateRange() {
    if (this.screeningsDateRange == DateRange.Day) {
      this.screeningsGranularity = Granularity.Hour;
    }
  }

  get screeningsChartData(): DateLineChartData {
    return calculateScreeningsChartData(
      this.screenings,
      this.screeningsDateRange,
      this.screeningsGranularity
    );
  }
}

import { ChartData } from "chart.js";

export enum DateRange {
  Day = 1,
  Week = 7,
  Month = 28
}

export const dateRangeOptions = [
  { text: "Last 24 Hours", value: DateRange.Day },
  { text: "Last 7 Days", value: DateRange.Week },
  { text: "Last Month", value: DateRange.Month }
];

export enum Granularity {
  Hour,
  Day
}

export const granularityOptions = [
  { text: "Every Hour", value: Granularity.Hour },
  { text: "Every Day", value: Granularity.Day }
];

export type DateLineChartData = ChartData<"line", { y: number; x: Date }[], Date>;
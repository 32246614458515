




















































import { Component, Inject, Vue } from "vue-property-decorator";
import TotalSheet from "@/components/analytics/shared/total-sheet.vue";
import DateRangeSelect from "@/components/analytics/shared/date-range-select.vue";
import GranularitySelect from "@/components/analytics/shared/granularity-select.vue";
import NumberSelect from "@/components/analytics/shared/number-select.vue";
import AnalyticsService, { RulesStats, AnalyticsData } from "@/services/analytics-service";

import { DateRange } from "@/helpers/analytics";
import {
  calculatePopularRules,
  RulesStatsWithPercentage
} from "@/components/analytics/hazcheck-detect/hazcheck-detect-func";

@Component({
  components: {
    TotalSheet,
    DateRangeSelect,
    GranularitySelect,
    NumberSelect
  }
})
export default class HazcheckDetectPopularRulesTable extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;

  rules: AnalyticsData<RulesStats> | null = null;
  loading = true;

  popularRulesCount = 15;
  popularRulesDateRange = DateRange.Week;

  getRuleLink(rule: RulesStats) {
    return `/libraries/${rule.LibraryId}/rules/${rule.RuleId}`;
  }

  async mounted() {
    await this.fetchAnalytics();
  }

  async fetchAnalytics() {
    this.rules = await this.AnalyticsService.readRules();
    this.loading = false;
  }

  get popularRulesTableData(): RulesStatsWithPercentage[] {
    this.loading = true;
    const rules = calculatePopularRules(
      this.rules,
      this.popularRulesDateRange,
      this.popularRulesCount
    );
    this.loading = false;
    return rules;
  }
}

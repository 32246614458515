























import { Component, Inject, Vue } from "vue-property-decorator";
import TotalSheet from "@/components/analytics/shared/total-sheet.vue";
import DateRangeSelect from "@/components/analytics/shared/date-range-select.vue";
import GranularitySelect from "@/components/analytics/shared/granularity-select.vue";
import NumberSelect from "@/components/analytics/shared/number-select.vue";
import AnalyticsService, {
  ScreeningStats,
  RulesStats,
  AnalyticsData
} from "@/services/analytics-service";

import HazcheckDetectScreeningsChart from "./data-display/hazcheck-detect-screenings-chart.vue";
import HazcheckDetectPopularRulesTable from "./data-display/hazcheck-detect-popular-rules-table.vue";

@Component({
  components: {
    TotalSheet,
    DateRangeSelect,
    GranularitySelect,
    NumberSelect,
    HazcheckDetectScreeningsChart,
    HazcheckDetectPopularRulesTable
  }
})
export default class HazcheckDetectAnalytics extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;

  screenings: AnalyticsData<ScreeningStats> | null = null;
  rules: AnalyticsData<RulesStats> | null = null;
  loading = true;

  get totalScreenings() {
    return this.screenings?.data?.reduce((a, b) => a + b.Screenings, 0);
  }
  get analyticsCollectionStartDate() {
    const sorted = this.screenings?.data.sort(
      (a, b) => a.Timestamp.getTime() - b.Timestamp.getTime()
    );
    return sorted && sorted.length && sorted[0].Timestamp;
  }

  get lastUpdated() {
    return this.screenings?.lastUpdated;
  }

  async mounted() {
    await this.fetchAnalytics();
  }

  async fetchAnalytics() {
    this.screenings = await this.AnalyticsService.readScreenings();
    this.loading = false;
  }
}






















import { Component, Inject, Vue } from "vue-property-decorator";
import AnalyticsService, { StatusStats, AnalyticsData } from "@/services/analytics-service";
import { DateLineChartData, DateRange, Granularity } from "@/helpers/analytics";
import { ChartOptions } from "chart.js";
import LineChart from "@/components/charts/LineChart.vue";
import { AllStatusesConfig } from "@/services/configuration-service";
import { calculateActivityChartData } from "../case-management-func";
import TotalSheet from "../../shared/total-sheet.vue";
import DateRangeSelect from "../../shared/date-range-select.vue";
import GranularitySelect from "../../shared/granularity-select.vue";

@Component({
  components: {
    LineChart,
    TotalSheet,
    DateRangeSelect,
    GranularitySelect
  }
})
export default class CaseManagementActivityChart extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;

  display: any = {};
  loading = true;

  activity: AnalyticsData<StatusStats> | null = null;
  activityDateRange = DateRange.Week;
  activityGranularity = Granularity.Hour;
  activityChartOptions: ChartOptions<"line"> = {
    plugins: {
      decimation: {
        enabled: true,
        algorithm: "lttb"
      }
    },
    interaction: {
      intersect: false,
      axis: "x"
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  async mounted() {
    await this.fetchAnalytics();
  }

  async fetchAnalytics() {
    this.activity = await this.AnalyticsService.readActivity();
    this.loading = false;
  }

  metadataConfig: AllStatusesConfig = { statuses: [] };

  get activityChartData(): DateLineChartData {
    return calculateActivityChartData(
      this.activity,
      this.activityDateRange,
      this.activityGranularity
    );
  }
}












import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import { Chart, ChartData, ChartOptions } from "chart.js";

@Component({
  inheritAttrs: false
})
export default class LineChart extends Vue {
  @Prop({ required: true }) readonly data!: ChartData<"line", number[], Date>;
  @Prop({ required: true }) readonly options!: ChartOptions<"line">;
  @Prop({ default: false }) readonly loading!: boolean;

  @Ref() readonly canvas!: HTMLCanvasElement;
  chart!: Chart<"line", number[], Date>;

  mounted() {
    this.createChart();
  }

  @Watch("data", { deep: true })
  updateChart() {
    this.chart.data.labels = this.data.labels;
    this.chart.data.datasets = this.data.datasets;

    this.chart.update();
  }

  createChart() {
    this.chart = new Chart<"line", number[], Date>(this.canvas, {
      type: "line",
      data: this.data,
      options: this.options
    });
  }
}

import { DateLineChartData, DateRange, Granularity } from "@/helpers/analytics";
import { AnalyticsData, RulesStats, ScreeningStats } from "@/services/analytics-service";
import dayjs from "dayjs";

export function calculateScreeningsChartData(
  screenings: AnalyticsData<ScreeningStats> | null,
  screeningsDateRange: DateRange,
  screeningsGranularity: Granularity
): DateLineChartData {

    if (screenings == null) {
      return { datasets: [] };
    }

    const now = dayjs().utc();
    const from = now.subtract(screeningsDateRange, "days");
    const zeros = [] as ScreeningStats[];
    let date = from.startOf("hour");
    while (date.isBefore(now)) {
      zeros.push({
        Timestamp: date.toDate(),
        Screenings: 0,
        "With hits": 0,
        "Unique Bookings": 0
      });
      date = date.add(1, "hour");
    }

    const compare = from.startOf('hour');

    let slice = zeros.concat(
      screenings.data.filter(x => compare.isSameOrBefore(x.Timestamp))
    );

    slice = slice
      .reduce(
        (arr, stat) => {
          const timestamp = dayjs(stat.Timestamp)
            .utc()
            .startOf(screeningsGranularity == Granularity.Day ? "day" : "hour")
            .toDate();
          const existing = arr.find(
            x => x.Timestamp.getTime() == timestamp.getTime()
          );
          if (existing) {
            existing.Screenings += stat.Screenings;
            existing["With hits"] += stat["With hits"];
            existing["Unique Bookings"] += stat["Unique Bookings"];

            return arr;
          }
          return [...arr, { ...stat, Timestamp: timestamp }];
        },
        [] as ScreeningStats[]
      );

    return {
      datasets: [
        {
          label: "Screenings",
          data: slice.map(x => ({ y: x.Screenings, x: x.Timestamp })),
          fill: true,
          borderWidth: 1,
          borderColor: "rgb(75, 192, 100)",
          backgroundColor: "rgb(75, 192, 100, 0.3)",
          tension: 0.2,
          pointRadius: 0
        },
        {
          label: "With Hits",
          data: slice.map(x => ({ y: x["With hits"], x: x.Timestamp })),
          fill: true,
          borderWidth: 1,
          borderColor: "rgb(230, 90, 72)",
          backgroundColor: "rgb(230, 90, 72, 0.3)",
          tension: 0.2,
          pointRadius: 0
        }
      ]
    };
}

export interface RulesStatsWithPercentage extends RulesStats {
  Percentage: number;
}

export function calculatePopularRules(
  rules: AnalyticsData<RulesStats> | null,
  popularRulesDateRange: DateRange,
  topSelected: number
): RulesStatsWithPercentage[]{
  if (rules == null) {
    return [];
  }
  const from = dayjs().utc().subtract(popularRulesDateRange, "days");
  const compare = from.startOf('hour');

  const data = rules.data.reduce((stats, rule) => {
    
    const isInDateRange = compare.isSameOrBefore(rule.Date);
    if (rule.RuleId in stats) {
      const currentStats = stats[rule.RuleId];
      return {
        ...stats,
        [rule.RuleId]: {
          ...currentStats,
          Count: currentStats.Count + (isInDateRange ? rule.Count : 0)
        }
      };
    }

    if (isInDateRange) {
      return {
        ...stats,
        [rule.RuleId]: rule
      };
    }

    return stats;
  }, {} as { [key: string]: RulesStats });

  const totalRuleHits = Object.values(data).reduce((a, b) => a + b.Count, 0);
  let result = Object.values(data)
    .sort((a, b) => b.Count - a.Count)
    .map((rule) => { return { ...rule, Percentage: (rule.Count * 100) / totalRuleHits } as RulesStatsWithPercentage});
  if (result.length > topSelected) {
    const nonDisplayedRules = result
      .slice(topSelected);
    const otherCount = nonDisplayedRules
      .reduce((tally, current) => tally + current.Count, 0);
    const otherRuleCount = nonDisplayedRules.length;

    result = result.slice(0, topSelected);
    result.push({
      RuleId: '',
      RuleName: `Other Rules (${otherRuleCount})`,
      LibraryId: '',
      LibraryName: '',
      Count: otherCount,
      Percentage: (otherCount * 100) / totalRuleHits
    } as RulesStatsWithPercentage)
  }

  return result;
}


























import { Component, Vue } from "vue-property-decorator";

import CaseManagementActivityChart from "./data-display/case-management-activity-chart.vue";
import CaseManagementStatusChart from "./data-display/case-management-status-chart.vue";
import CaseManagementMetadataTable from "./data-display/case-management-metadata-table.vue";

@Component({
  components: {
    CaseManagementActivityChart,
    CaseManagementStatusChart,
    CaseManagementMetadataTable
  }
})
export default class CaseManagementAnalytics extends Vue {}




























import { Vue, Prop, PropSync, Component } from 'vue-property-decorator'

@Component({})
export default class DateSelect extends Vue {
  @PropSync('date', { type: String }) dateSync: undefined|string;
  @Prop() label: undefined|string
}
